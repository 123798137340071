import { useMutation } from "react-query";
import { useState } from "react";
import { PostPhoto, PostPhotoResponse } from "../networks/PostPhoto";
import { LineUser } from "../domains/LineUser";

export function usePostPhoto(
  user: LineUser | undefined | null,
  onMutate: () => void,
  onSettled: () => void
) {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const { mutate } = useMutation(
    ({
      files,
    }: {
      files: FileList | null;
      onChange: any;
      onFileChange: any;
    }) => {
      // TODO: mock
      // if (process.env.NODE_ENV === "development") {
      //   return delay(3000).then(() => {
      //     return {
      //       fileName: "aaa",
      //       filePath: "BBB",
      //     };
      //   }) as Promise<PostPhotoResponse>;
      // }

      return new PostPhoto(process.env.REACT_APP_APPLICATION_ID!, user)
        .file(files)
        .send();
    },
    {
      onMutate: () => {
        onMutate();
        setError(undefined);
        setUploading(true);
      },
      onSuccess: (response: PostPhotoResponse, variables) => {
        setFileName(response.fileName);
        variables.onChange(response.fileName);
        variables.onFileChange(variables.files);
      },
      onError: (error: Error, variables) => {
        setError(error);
        variables.onChange(undefined);
        variables.onFileChange(undefined);
      },
      onSettled: () => {
        setUploading(false);
        onSettled();
      },
    }
  );
  return {
    upload: mutate,
    uploading,
    error,
    fileName,
  };
}
