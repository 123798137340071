import React, { useState } from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../hooks/useGetLineUser";
import { useGetStatus } from "../../hooks/useGetStatus";
// Components
import { TitleField } from "../../components/forms/TitleField";
import { Redirect } from "../../components/utils/Redirect";
import { Status } from "../../domains/Status";
import dayjs from "dayjs";
import "dayjs/locale/ja";

import MapImage from "./annaizu.jpeg";


import { Form } from "react-final-form";
import { ErrorNotification } from "../../components/elements/ErrorNotification";
import { TextInputField } from "../../components/forms/TextInputField";
import { SubmitButtonField } from "../../components/forms/SubmitButtonField";
import CameratestValue, { ICameratestValue } from "../../domains/CameratestValue";
import axios from "axios";
import { FORM_ERROR } from "final-form";
import { YesNoField } from "../../components/forms/YesNoField";
import { TextareaField } from "../../components/forms/TextareaField";

dayjs.locale("ja");

export default mount({
  "/": route({
    getView: () => <FormThanksPage />,
  }),
});

const FormThanksPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);
  const [showModal, setShowModal] = useState<boolean>(false);

  if (status?.status !== Status.ADDITIONAL_QUESTION) {
    return <Redirect url="/" />;
  }

  return (
    <div>
      <Helmet>
        <title>
          3次面接のお知らせ | quadインターン2021(demo)
        </title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <TitleField
              title="quadインターン2021(demo)"
              subtitle="3次面接のお知らせ"
            />
            <hr />
            <div className="field">{`${status?.entry_data.name} 様`}</div>
            <div className="field">
              この度は、quadインターン2021(demo)へのご参加、ありがとうございました。
            </div>
            <div className="field">
              グループ面談の結果、{`${status?.entry_data.name} 様`}には、是非、3次面接にご参加いただきたく思います。
              <br />
              <br />
              つきましては、直前となり大変恐縮ですが、参加の可否の回答と、新型コロナウィルス感染症対策のための住所入力にご協力ください。
              <br />
              <br />
              以上、何卒よろしくお願い申し上げます。
              <br />
              <br />
              <div className="message is-info">
                <div className="message-header">
                  <p>3次面接・2021年9月1日(水)</p>
                </div>
                <div className="message-body">

                  <Form<ICameratestValue>
                    onSubmit={onSubmit}
                    // validate={(values) => CameratestValue.validate(values)}
                    validate={(values) => CameratestValue.validate(values)}
                    initialValues={CameratestValue.getInitialValues({ ...status?.entry_data })}
                  >
                    {({ handleSubmit, submitting, submitError, values }) => (
                      <form onSubmit={handleSubmit}>
                        <section className="section">
                          <ErrorNotification error={submitError}/>
                          <YesNoField name="participate" label={`3次面接に参加しますか?`} required={true}/>
                          <TextInputField
                            name="name"
                            label="名前"
                            placeholder="姓　名"
                            width="medium"
                            required
                          />
                          <TextInputField
                            name="address"
                            label="住所"
                            placeholder="東京都渋谷区X-X-X"
                            width="medium"
                            required
                          />
                          <TextareaField
                            name="schedule"
                            label="当日の予定について特筆事項あればご記入ください"
                            placeholder="全日調整可能です。特に予定はありません"
                          />
                          <SubmitButtonField
                            disabled={false}
                            label="回答する"
                          />
                        </section>
                      </form>
                    )}
                  </Form>

                  <p>
                    <span className="tag is-info">日時</span> 2021年9月1日(水){" "}
                    <strong className="has-text-danger has-background-danger-light">
                      11:00 集合
                    </strong>
                    <span className="is-size-7 has-text-grey">
                      ※16時頃までには解散予定
                    </span>
                  </p>
                  <p>
                    <span className="tag is-info">集合場所</span>{" "}
                    <a
                      href="https://www.fujimediahd.co.jp/corporate/access.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      〒163-8001 東京都新宿区西新宿2-8-1　
                      集合：オフィスタワー1F 入口
                    </a>
                    <br />
                  </p>
                  <figure style={{ maxWidth: "600px", padding: "10px" }}>
                    <img src={MapImage} alt="オフィスタワー案内図" />
                  </figure>

                  <br />
                  <div className="content">
                    <p>
                      集合場所に到着されましたら、係の者が立っておりますのでお名前をお伝えください。
                    </p>
                    <p className="has-text-danger has-text-weight-bold">
                      集合時に消毒および検温がございます。発熱がみられる方は恐れ入りますがご参加いただけませんので予めご了承ください。
                      <br />
                      また、必ずご自宅等でも検温の上、体調に異変がみられる場合は参加をお控えいただきますようお願い申し上げます。
                      <br />
                      その他、緊急連絡はすべてLINEにてお願いいたします。
                    </p>
                  </div>
                </div>
              </div>
              <div className="message is-warning">
                <div className={`message-body`}>
                  なお、参加にかかる交通費・宿泊費につきましては、ご自身にてご負担いただくことになります点、予めご容赦いただけますと幸いです。
                </div>
              </div>
              <br />
              <span className="is-pulled-right">
                quadインターン2021(demo)採用事務局
              </span>
            </div>
            <hr />
            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className={classNames("modal", {
          "is-active": showModal,
        })}
      >
        <div
          className="modal-background"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <div className="modal-content">
          <div className="notification is-success">
            回答を保存しました。変更する場合は再度回答ください。
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>
    </div>
  );

  async function onSubmit(values: ICameratestValue) {
    const value: CameratestValue = CameratestValue.fromJSON(values);
    try {
      await axios.put(`https://app.q-uad.me/api/entry.json`, value.toRequest(), {
        headers: {
          "x-access-token": process.env.REACT_APP_APPLICATION_ID,
          "x-line-access-token": user?.["line-access-token"]
        }
      });
      setShowModal(true);
    } catch (error) {
      const formError = parseError(error);
      window.scrollTo(0, 0);
      return formError;
    }
  }

  function parseError(error: any) {
    return { [FORM_ERROR]: JSON.stringify(error) };
  }
};
