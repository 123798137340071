
export interface ICameratestValue {
  name?: string;
  address?: string;
  schedule?: string;
  participate?: boolean;
}

export default class CameratestValue {
  name: string;
  address: string;
  participate: boolean;

  schedule?: string;

  constructor(value: ICameratestValue) {
    // Required
    this.name = value.name!;
    this.address = value.address!;
    this.participate = value.participate!;
    this.schedule = value.schedule;
  }

  // NOTE:- サーバに送るJSONデータを作成
  toRequest() {
    return {
      entry_data: {
        name: this.name,
        address: this.address,
        participate: this.participate,
        schedule: this.schedule,
      }
    };
  }

  static initialValues = {

  };

  static getInitialValues = (values: Partial<CameratestValue>) => {
    return {
      ...CameratestValue.initialValues,
      ...values,
    };
  };

  static fromJSON = (values: ICameratestValue) => {
    return new CameratestValue(values);
  };

  static validate = (values: ICameratestValue) => {
    const errors: any = {};
    const {
      name,
      address,
      participate,
    } = values;
    if (!name) {
      errors.name = "名前を入力してください";
    }
    if(!address){
      errors.address = "住所を入力してください";
    }
    if(!participate){
      errors.participate = "参加の可否を入力してください";
    }
    return errors;
  };
}
