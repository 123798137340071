import styled from "styled-components/macro";
import classNames from "classnames";

const StyledContainer = styled.div`
  max-width: 480px;
`;

export const Container = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <StyledContainer
      {...props}
      className={classNames("container", props.className)}
    />
  );
};
