import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../hooks/useGetLineUser";
import { useGetStatus } from "../../hooks/useGetStatus";
// Components
import { TitleField } from "../../components/forms/TitleField";
import { Redirect } from "../../components/utils/Redirect";
import { Status } from "../../domains/Status";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { LineUser } from "../../domains/LineUser";

dayjs.locale("ja");

export default mount({
  "/": route({
    getView: () => <FormMeetingPage/>
  })
});

const FormMeetingPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);
  const [meetings, setMeetings] = useState<any[]>([]);
  const [joined, setJoined] = useState<any>(null);

  useEffect(() => {
    fetchMeetings(user);

    axios.get(`https://app.q-uad.me/api/meeting/joined/2nd-group.json`, {
      headers: {
        "x-access-token": process.env.REACT_APP_APPLICATION_ID,
        "x-line-access-token": user?.["line-access-token"]
      }
    }).then((response) => {
        if (response.data?.result.participant?.meeting !== undefined) {
          setJoined(response.data?.result.participant?.meeting);
        }
      }
    );

  }, [user]);


  const fetchMeetings = (user: LineUser | undefined) => {
    axios.get(`https://app.q-uad.me/api/meeting/2nd-group/list.json`, {
      headers: {
        "x-access-token": process.env.REACT_APP_APPLICATION_ID,
        "x-line-access-token": user?.["line-access-token"]
      }
    }).then((response) => {
      setMeetings(response.data?.result.meetings);
    });
  };

  if (status?.status === Status.NOT_APPLY) {
    window.location.href = "https://www.q-uad.me/";
  }
  if (status?.status !== Status.PASS_1ST && status?.status !== Status.FIX_2ND) {
    return <Redirect url="/"/>;
  }

  const join = (meetingId: number) => {
    axios.post(`https://app.q-uad.me/api/meeting/join.json`, {
      "meetingId": meetingId
    }, {
      headers: {
        "x-access-token": process.env.REACT_APP_APPLICATION_ID,
        "x-line-access-token": user?.["line-access-token"]
      }
    }).then((response) => {
      setJoined(response.data?.result.meeting);
      fetchMeetings(user);
    }).catch(error => {
      alert(error.response.data.message);
      window.location.reload();
    });
  };

  const quit = (meetingId: number) => {
    axios.post(`https://app.q-uad.me/api/meeting/quit.json`, {
      "meetingId": meetingId
    }, {
      headers: {
        "x-access-token": process.env.REACT_APP_APPLICATION_ID,
        "x-line-access-token": user?.["line-access-token"]
      }
    }).then((response) => {
      setJoined(null);
      fetchMeetings(user);
    }).catch(error => {
      alert(error.response.data.message);
    });
  };

  const renderMeetingList = (meeting: any) => {
    return (
      <div key={meeting.id} style={{
        "display": "inline-block",
        "border": "1px solid #ccc",
        "padding": "10px",
        "margin": "10px",
        "borderRadius": "5px",
        "width": "100%"
      }}>
        <div className="tag is-outlined is-large">
          {dayjs(meeting.start).format("MM月DD日(ddd) HH:mm 開始")}
        </div>
        <div style={{ "marginTop": "10px" }}>
          {
            (meeting.remain > 0 && joined === null) &&
            <button className="button is-success" onClick={() => {
              join(meeting.id);
            }}>参加申込</button>
          }
          {
            (meeting.remain > 0 && joined !== null) &&
            <button className="button is-success" disabled>申込済</button>
          }
          {
            (meeting.remain <= 0) &&
            <button className="button is-danger" disabled>定員に達しました</button>
          }
        </div>
      </div>
    );
  };


  return (
    <div>
      <Helmet>
        <title>オンライングループ面談のお知らせ | quadインターン2021(demo)</title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <TitleField
              title="quadインターン2021(demo)"
              subtitle="オンライングループ面談のご案内"
            />
            <hr/>
            <div className="field">
              {`${status?.entry_data.name} 様`}
            </div>
            <div className="field">
              この度はquadインターン2021(demo)にエントリーいただきありがとうございます。<br/>
              <br/>
              ご提出いただいた書類を拝見し、ぜひ二次審査のオンライングループ面談に進んでいただきたく、ご連絡致しました。<br/>
              <br/>
              つきましては、以下の日程より<strong className="has-text-danger">【8月22日(日) 18:00までに】</strong>参加希望のオンライングループ面談の日程を申し込みください。<br/>
              <br/>
              それでは、引き続き何卒よろしくお願い申し上げます。<br/>
              <br/>
              <br/>
              <span className="is-pulled-right">quadインターン2021採用事務局</span>
            </div>
            <hr/>
            <div className="field message">
              ※ 日程の申込みは先着順となります。<br/>
              ※ 使用ツールは<strong className="has-text-danger">Zoom</strong>となります。<br/>
              <strong className="has-text-danger">※ 締切後LINEあるいはこちらの画面よりZoomのミーティングID/PINをお知らせいたします。</strong>
            </div>
            <hr/>
            <div className="field">
              <span className="is-size-6 tag is-outlined">申込状況</span>
              {
                (joined !== null) ?
                  <>
                  <span className="tag is-success is-large">
                    {`${dayjs(joined.start).format("MM月DD日(ddd) HH:mm")} 開始`}
                </span><br/> <br/>
                    {
                      (status?.status === Status.PASS_1ST) ?
                      <button className="button is-danger" onClick={() => {
                        quit(joined.id);
                      }}>申込のキャンセル
                      </button>
                        :
                        <div className="notification has-background-info-light">
                          (1) グループ面談中に<strong>課題発表</strong>がございます。<br />
                          必ず事前に以下の課題をダウンロードの上、プリントアウトするなど手元にご準備ください。
                          <br />
                          <br />
                          <a target="_blank" href="/script.pdf" className="button is-danger">課題(.pdf)</a>　
                          <a target="_blank" href="/script.docx"  className="button is-info">課題(.docx)</a>
                          <br />
                          <span className="help">※どちらも内容は同じです</span>
                          <br />
                          (2) グループ面談中に<strong>グループディスカッション</strong>がございます。<br />
                          グループディスカッションのテーマについては面談中に発表いたします。<br />
                          <br />
                          (3) お時間になりましたら以下のZoomミーティングルームに参加ください、<br />
                          ミーティングID: <span className="has-text-danger has-background-danger-light" style={{"padding":"0 0.25em"}}>{joined.meta_data.zoomID} </span><br />
                          パスコード:  <span className="has-text-danger has-background-danger-light" style={{"padding":"0 0.25em"}}>{joined.meta_data.zoomPIN}</span><br />
                          参加URL: <span className="has-text-danger has-background-danger-light" style={{"padding":"0 0.25em"}}>{joined.meta_data.zoomUrl}</span><br />
                          <div style={{padding:"20px", textAlign:"center"}}>
                            <a href={joined.meta_data.zoomUrl} className="button is-primary is-large" target="_blank" rel={"noreferrer"}>Zoomを起動</a>
                          </div>
                          <span className="has-background-danger-light has-text-danger-dark">※参加の際にZoomの表示名(スクリーンネーム)をエントリー時に入力したお名前に変更ください。</span><br />
                          <span className="has-background-danger-light has-text-danger-dark">※選考活動に利用するために面談は録画させていただいております。予めご了承ください。</span><br />
                        </div>
                    }
                  </>
                  :
                  <span className="tag is-warning is-large">
                  未申込
                  </span>
              }
            </div>
            <hr/>
            <div style={{ "textAlign": "center" }}>
              {
                (joined === null) && (
                  <>
                    {meetings.map(renderMeetingList)}
                    <hr/>
                  </>
                )
              }
            </div>
            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
