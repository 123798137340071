import React from "react";
import { Field } from "react-final-form";
import classNames from "classnames";
import { FieldLabel } from "./FieldLabel";
import { Application } from "../../domains/Application";

interface IAgreementFieldProps {
  classname?: string;
  label?: React.ReactNode;
  required?: boolean;
  name: string;
  application: Application;
}

export const AgreementField = ({
  application,
  classname,
  required,
  name,
  label,
}: IAgreementFieldProps) => {
  return (
    <Field name={name} type="checkbox">
      {({ input, meta: { touched, error } }) => (
        <div className={classNames("field", classname)}>
          {label && <FieldLabel required={required}>{label}</FieldLabel>}
          <div className="control is-flex">
            <input
              {...input}
              type="checkbox"
              id={input.name}
              className={classNames("is-checkradio is-link")}
            />
            <label htmlFor={input.name} style={{ paddingTop: 0 }}>
              書類提出について、
              <a
                href={application.privacy_policy_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                プライバシーポリシー
              </a>
              に同意して行います。
              <br />
              未成年者については、保護者様の同意を得ていることを確認します。
              <br />
              <p
                className={classNames("help", {
                  "is-danger": touched && error,
                  "is-hidden": !(touched && error),
                })}
              >
                {touched && error ? error : ""}
              </p>
            </label>
          </div>
        </div>
      )}
    </Field>
  );
};
