import React from "react";
import classNames from "classnames";

interface FieldLabelProps {
  required?: boolean;
  children: React.ReactNode;
}

export const FieldLabel = ({
  children,
  required,
  htmlFor,
}: FieldLabelProps & React.LabelHTMLAttributes<HTMLLabelElement>) => {
  return (
    <label htmlFor={htmlFor} className={classNames("FormLabel label tags")}>
      <span className="tag">{children}</span>
      {required ? (
        <span className="tag is-danger">必須</span>
      ) : (
        <span className="tag">任意</span>
      )}
    </label>
  );
};
