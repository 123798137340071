import React from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../hooks/useGetLineUser";
import { useGetStatus } from "../../hooks/useGetStatus";
// Components
import { TitleField } from "../../components/forms/TitleField";
import { Redirect } from "../../components/utils/Redirect";
import { Status } from "../../domains/Status";
import Logo from "../input/logo.svg";

export default mount({
  "/": route({
    getView: () => <FormAppliedPage />,
  }),
});

const FormAppliedPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);

  console.log(status);
  if (status?.status !== Status.FAIL_1ST) {
    return <Redirect url="/" />;
  }

  return (
    <div>
      <Helmet>
        <title>書類提出完了 | quadインターン2021</title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <img src={Logo} alt="quadインターン2021" style={{ "maxWidth": "80%",marginBottom:"20px" }}/>
            <TitleField
              title="エントリーフォーム"
            />
            <hr />
            <div className="field">
              {`${status?.entry_data.name} 様`}
            </div>
            <div className="field">
              この度は、quadインターン2021(demo)へのエントリーおよび書類提出ありがとうございました。
            </div>
            <div className="field">
              ご提出いただいた書類をもとに慎重に選考させていただいた結果、誠に残念ではありますが、ご期待に添えない結果となりました。申し訳ございません。<br />
              <br />
             「quadインターン2021(demo)」には大変多くのご応募をいただき、大変苦慮した上での決定であることを申し添えます。<br />
              <br />
              新型コロナウイルスの影響で慣れない学生生活を送られている中、エントリーしていただきましたこと、重ねて御礼申し上げます。ありがとうございました。<br />
              <br />
              なお、選考結果に関するお問い合わせ等は一切お受けできませんので、ご了承ください。<br />
              <br />
              <br />
              末筆にはなりますが、 {`${status?.entry_data.name}様`}の今後のご活躍を心よりお祈り申し上げます。
              <br />
              <br />
              <br />
              <span className="is-pulled-right">quadインターン2021採用事務局</span>
            </div>
            <hr />
            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
