/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
import { useNavigation } from "react-navi";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import styled from "styled-components/macro";
// Domains
import FormValue, { IFormValue } from "../../domains/FormValue";
// Hooks
import { useGetLineUser } from "../../hooks/useGetLineUser";
import { useGetStatus } from "../../hooks/useGetStatus";
import { usePostEntry } from "../../hooks/usePostEntry";
// Components
import { TitleField } from "../../components/forms/TitleField";
import { TextInputField } from "../../components/forms/TextInputField";
import { PhoneInputField } from "../../components/forms/PhoneInputField";
import { EmailInputField } from "../../components/forms/EmailInputField";
import { TextareaField } from "../../components/forms/TextareaField";
import {
  PhotoUploader,
  PhotoUploadField
} from "../../components/forms/PhotoUploadField";
import { ErrorNotification } from "../../components/elements/ErrorNotification";
import { AgreementField } from "../../components/forms/AgreementField";
import { SubmitButtonField } from "../../components/forms/SubmitButtonField";
import { Redirect } from "../../components/utils/Redirect";
import { Container } from "../../components/layouts/Container";
import { Status } from "../../domains/Status";

import Logo from "./logo.svg";
import axios from "axios";

export default mount({
  "/": route({
    getView: () => <FormInputPage />
  })
});

const FormInputPage = () => {
  const os = liff.getOS();
  const navigation = useNavigation();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);
  const { entry } = usePostEntry(user);
  const [photoUploading, setPhotoUploading] = useState({
    picture: false,
    full_body_picture: false,
    bust_up_picture: false
  });
  const [draft, setDraft] = useState<any>({});

  useEffect(() => {
    axios.get(`https://app.q-uad.me/api/draft`, {
      headers: {
        "x-access-token": process.env.REACT_APP_APPLICATION_ID,
        "x-line-access-token": user?.["line-access-token"]
      }
    }).then((res) => {
      setDraft({ ...res.data?.entry_data });
    });
  }, [user]);

  /** 暫定的にルーティングはここ　**/

  if (status?.status === Status.APPLIED) {
    return <Redirect url="/applied" />;
  }
  if (status?.status === Status.FAIL_1ST) {
    return <Redirect url="/result" />;
  }
  if (status?.status === Status.PASS_1ST || status?.status === Status.FIX_2ND) {
    return <Redirect url="/2nd" />;
  }
  if (status?.status === Status.ADDITIONAL_QUESTION) {
    return <Redirect url="/3rd" />;
  }
  if (status?.status === Status.ANNOUNCE) {
    return <Redirect url="/4th" />;
  }
  if (status?.status !== Status.NOT_APPLY) {
    return <Redirect url="/" />;
  }


  /** ここまで **/

  const drafting = (values: IFormValue) => {
    const value = FormValue.fromJSON(values);
    axios.post(`https://app.q-uad.me/api/draft`, {
        "entry_data": { ...value }
      },
      {
        headers: {
          "x-access-token": process.env.REACT_APP_APPLICATION_ID,
          "x-line-access-token": user?.["line-access-token"]
        }
      });
  };
  return (
    <div>
      <Helmet>
        <title>書類提出 | quadインターン2021(demo)</title>
      </Helmet>
      <Page className="Page FormInputPage" os={os}>
        <Form<IFormValue>
          onSubmit={onSubmit}
          validate={(values) => FormValue.validate(values)}
          initialValues={FormValue.getInitialValues(draft)}
        >
          {({ handleSubmit, submitting, submitError, values }) => (
            <form onSubmit={handleSubmit}>
              <section className="section">
                <Container>
                  <img src={Logo} alt="quadインターン2021" style={{ "maxWidth": "80%", marginBottom: "10px" }} />
                  <TitleField
                    title="エントリー書類提出フォーム"
                  />
                  <hr />
                  <ErrorNotification error={submitError} />
                  {/*=============================
                    NOTE:- Place Your Form Fields
                  =============================*/}
                  <TextInputField
                    name="name"
                    label="名前"
                    placeholder="姓　名"
                    width="medium"
                    required
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextInputField
                    name="name_kana"
                    label="フリガナ"
                    placeholder="セイ　メイ"
                    width="medium"
                    required
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextInputField
                    name="university"
                    label="大学"
                    placeholder="●●大学"
                    width="large"
                    required
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextInputField
                    name="faculty"
                    label="学部・学科・学年"
                    placeholder="経済学部経済学科3年"
                    width="large"
                    required
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextInputField
                    name="circle"
                    label="所属サークル/ゼミ"
                    placeholder="●●●●研究室"
                    width="large"
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <EmailInputField
                    name="email"
                    label="メールアドレス"
                    placeholder="xxxxx@q-uad.me"
                    width="large"
                    required
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <PhoneInputField
                    name="tel"
                    label="電話番号"
                    placeholder="XXX-XXXX-XXXX"
                    width="medium"
                    required
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <PhotoUploader
                    name="bust_up_picture"
                    user={user}
                    onMutate={() =>
                      setPhotoUploading(prevState => {
                        return { ...prevState, bust_up_picture: true };
                      })
                    }
                    onSettled={() =>
                      setPhotoUploading(prevState => {
                        return { ...prevState, bust_up_picture: false };
                      })
                    }
                  >
                    {(props) => (
                      <>
                        <PhotoUploadField
                          {...props}
                          name={props.input.name}
                          label="顔写真（3ヶ月以内に撮影したもの）"
                          required
                        />
                      </>
                    )}
                  </PhotoUploader>
                  <PhotoUploader
                    name="picture"
                    user={user}
                    onMutate={() =>
                      setPhotoUploading(prevState => {
                        return { ...prevState, picture: true };
                      })
                    }
                    onSettled={() =>
                      setPhotoUploading(prevState => {
                        return { ...prevState, picture: false };
                      })
                    }
                  >
                    {(props) => (
                      <>
                        <PhotoUploadField
                          {...props}
                          name={props.input.name}
                          label="あなたらしい写真"
                          required
                        />
                      </>
                    )}
                  </PhotoUploader>
                  <TextareaField
                    label="今ハマっているものについて、教えてください"
                    required
                    name="obsessed_things"
                    width="medium"
                    help={<>※提出前にお手元にコピーを保存することを推奨します</>}
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextareaField
                    label="将来実現してみたい夢はなんですか?"
                    required
                    name="dream"
                    width="medium"
                    help={<>※提出前にお手元にコピーを保存することを推奨します</>}
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextareaField
                    label="目標としている人を教えてください"
                    required
                    name="aim_for"
                    width="medium"
                    help={<>※提出前にお手元にコピーを保存することを推奨します</>}
                    onBlur={() => {
                      drafting(values);
                    }}
                  />
                  <TextareaField
                    label="自己PR"
                    required
                    name="pr"
                    width="medium"
                    help={<>※提出前にお手元にコピーを保存することを推奨します</>}
                    onBlur={() => {
                      drafting(values);
                    }}
                  />

                  <AgreementField
                    name="agreement"
                    application={status.application}
                  />
                  <SubmitButtonField
                    submitting={submitting}
                    disabled={
                      photoUploading.full_body_picture || photoUploading.picture || photoUploading.bust_up_picture
                    }
                  />
                </Container>
              </section>
            </form>
          )}
        </Form>
      </Page>
    </div>
  );

  async function onSubmit(values: IFormValue) {
    const value: FormValue = FormValue.fromJSON(values);
    try {
      await entry(value);
      await navigation.navigate(`/applied`);
    } catch (error) {
      try {
        await axios.put(`https://app.q-uad.me/api/entry.json`, value.toRequest(), {
          headers: {
            "x-access-token": process.env.REACT_APP_APPLICATION_ID,
            "x-line-access-token": user?.["line-access-token"]
          }
        });
        await navigation.navigate(`/applied`);
      } catch (error) {
        const formError = parseError(error);
        window.scrollTo(0, 0);
        return formError;
      }
    }
  }

  function parseError(error: any) {
    const errors = error?.response?.data?.result?.errors;
    if (errors?.tel?._required) {
      return {
        [FORM_ERROR]:
        errors?.tel?._required ?? "電話番号の入力内容を確認してください"
      };
    } else if (errors?.email?._required) {
      return {
        [FORM_ERROR]:
        errors?.email?._required ??
        "メールアドレスの入力内容を確認してください"
      };
    }
    return { [FORM_ERROR]: JSON.stringify(error) };
  }
};

const Page = styled.div<{ os: "ios" | "android" | "web" }>`
  ${({ os }) =>
    os === "android"
      ? `
    padding-bottom: 60vh;
  `
      : `
    padding-bottom: 3rem;
  `}
`;
