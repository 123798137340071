import { Status } from "./Status";

export interface IFormValue {
  name?: string;
  name_kana?: string;
  university?: string;
  faculty?: string;
  email?: string;
  tel?: string;
  bust_up_picture?: string;
  picture?: string;
  pr?: string;
  circle?: string;
  agreement?: boolean;
  obsessed_things?: string;
  aim_for?: string;
  dream?: string;
}

export default class FormValue {
  name: string;
  name_kana: string;
  university: string;
  faculty: string;
  email: string;
  tel: string;
  bust_up_picture?: string;
  picture: string;
  pr: string;
  circle?: string;
  agreement?: boolean;
  obsessed_things?: string;
  aim_for?: string;
  dream?: string;



  constructor(value: IFormValue) {
    // Required
    this.name = value.name!;
    this.name_kana = value.name_kana!;
    this.university = value.university!;
    this.faculty = value.faculty!;
    this.email = value.email!;
    this.tel = value.tel!;
    this.bust_up_picture = value.bust_up_picture!;
    this.picture = value.picture!;
    this.pr = value.pr!;
    this.agreement = value.agreement!;
    this.obsessed_things = value.obsessed_things!;
    this.aim_for = value.aim_for;
    this.dream = value.dream;

    // Non-Required
    this.circle = value.circle;
  }

  // NOTE:- サーバに送るJSONデータを作成
  toRequest() {
    return {
      entry_data: {
        name: this.name,
        name_kana: this.name_kana,
        university: this.university,
        faculty: this.faculty,
        circle: this.circle,
        email: this.email,
        tel: this.tel,
        bust_up_picture: this.bust_up_picture,
        picture: this.picture,
        pr: this.pr,
        obsessed_things: this.obsessed_things,
        dream: this.dream,
        aim_for: this.aim_for,
      },
      status: Status.APPLIED,
    };
  }

  static initialValues = {
    agreement: false,
  };

  static getInitialValues = (values: Partial<IFormValue>) => {
    return {
      ...FormValue.initialValues,
      ...values,
    };
  };

  static fromJSON = (values: IFormValue) => {
    return new FormValue(values);
  };

  static validate = (values: IFormValue) => {
    const errors: any = {};
    const {
      name,
      name_kana,
      university,
      faculty,
      tel,
      email,
      picture,
      bust_up_picture,
      pr,
      agreement,
      obsessed_things,
      aim_for,
      dream
    } = values;
    if (!name) {
      errors.name = "名前を入力してください";
    }
    if (!name_kana) {
      errors.name_kana = "フリガナを入力してください";
    }
    if (!university) {
      errors.university = "学校を入力してください";
    }
    if (!faculty) {
      errors.faculty = "学部・学科・学年を入力してください";
    }
    if (!email) {
      errors.email = "メールアドレスを入力してください";
    } else if (!/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i.test(email)) {
      errors.email = "正しい形式で入力してください";
    }
    if (!tel) {
      errors.tel = "電話番号を入力してください";
    }
    if (!bust_up_picture) {
      errors.full_body_picture = "バストアップ写真を選択してください";
    }
    if (!picture) {
      errors.picture = "あなたらしい写真を選択してください";
    }
    if (!pr) {
      errors.pr = "自己PRを記入してください";
    }
    if (!agreement) {
      errors.agreement = "内容を確認して同意してください";
    }
    if(!obsessed_things){
      errors.obsessed_things = "今ハマっているものについて記入してください";
    }
    if(!dream){
      errors.dream = "将来実現してみたい夢について記入してください";
    }
    if(!aim_for){
      errors.aim_for = "目標としている人物について記入してください";
    }
    return errors;
  };
}
