import React, { useState } from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../hooks/useGetLineUser";
import { useGetStatus } from "../../hooks/useGetStatus";
// Components
import { TitleField } from "../../components/forms/TitleField";
import { Redirect } from "../../components/utils/Redirect";
import { Status } from "../../domains/Status";
import dayjs from "dayjs";
import "dayjs/locale/ja";

import MapImage from "./map_fuji.gif";

dayjs.locale("ja");

// import { Form } from "react-final-form";
// import { Container } from "../../../components/layouts/Container";
// import { ErrorNotification } from "../../../components/elements/ErrorNotification";
// import { TextInputField } from "../../../components/forms/TextInputField";
// import { SubmitButtonField } from "../../../components/forms/SubmitButtonField";
// import CameratestValue, { ICameratestValue } from "../../../domains/CameratestValue";
// import axios from "axios";
// import { FORM_ERROR } from "final-form";
// import { YesNoField } from "../../../components/forms/YesNoField";
// import { TextareaField } from "../../../components/forms/TextareaField";

export default mount({
  "/": route({
    getView: () => <FormThanksPage />,
  }),
});

const FormThanksPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);
  const [showModal, setShowModal] = useState<boolean>(false);

  if (status?.status !== Status.ANNOUNCE) {
    return <Redirect url="/" />;
  }

  return (
    <div>
      <Helmet>
        <title>
          オンライングループ面談結果のお知らせ | 第１回学生アナウンス大賞
        </title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <TitleField
              title=" 第１回学生アナウンス大賞"
              subtitle="表彰式のご案内"
            />
            <hr />
            <div className="field">{`${status?.entry_data.name} 様`}</div>
            <div className="field">
              この度は、学生アナウンス大賞カメラテストへのご参加いただき、ありがとうございました。
            </div>
            <div className="field">
              カメラテストの結果、{`${status?.entry_data.name} 様`}
              には、是非、明日23日の表彰式にご参加いただきたく思います。
              <br />
              <br />
              つきましては、直前となり大変恐縮ですが、明日の詳細についてお送りさせていただきますのでご確認ください。
              <br />
              何卒よろしくお願い申し上げます。
              <br />
              <br />
              <div className="message is-info">
                <div className="message-header">
                  <p>3月23日(火) 表彰式</p>
                </div>
                <div className="message-body">
                  <p>
                    <span className="tag is-info">日時</span> 2021年3月23日(火){" "}
                    <strong className="has-text-danger has-background-danger-light">
                      11:00 集合
                    </strong>
                    <span className="is-size-7 has-text-grey">
                      ※16時頃までには解散予定
                    </span>
                  </p>
                  <p>
                    <span className="tag is-info">集合場所</span>{" "}
                    <a
                      href="https://www.fujimediahd.co.jp/corporate/access.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      東京都港区台場2-4-8 フジテレビ本社屋内
                      集合：オフィスタワー1F 入口
                    </a>
                    <br />
                  </p>
                  <figure style={{ maxWidth: "600px", padding: "10px" }}>
                    <img src={MapImage} alt="オフィスタワー案内図" />
                  </figure>

                  <br />
                  <div className="content">
                    <p>
                      表彰式では、最後の自己PRスピーチ（1分程度）を行っていただく予定です。
                      <br />
                      表彰式イベント内にて各賞発表いたします。
                      <br />
                      ※受賞がない場合もございます
                    </p>
                    <p>
                      集合場所に到着されましたら、学生アナウンス大賞の紙を持った係の者が立っておりますのでお名前をお伝えください。
                    </p>
                    <p className="has-text-danger has-text-weight-bold">
                      集合時に消毒および検温がございます。発熱がみられる方は恐れ入りますがご参加いただけませんので予めご了承ください。
                      <br />
                      また、必ずご自宅等でも検温の上、体調に異変がみられる場合は参加をお控えいただきますようお願い申し上げます。
                      <br />
                      その他、緊急連絡はすべてLINEにてお願いいたします。
                    </p>
                  </div>
                </div>
              </div>
              <div className="message is-warning">
                <div className={`message-body`}>
                  なお、表彰式への参加にかかる交通費・宿泊費につきましては、公式サイトにてご案内しておりました通り、ご自身にてご負担いただくことになります点、予めご容赦いただけますと幸いです。
                </div>
              </div>
              <br />
              <span className="is-pulled-right">
                第1回 学生アナウンス大賞実行委員会
              </span>
            </div>
            <hr />
            {/*
            <Form<ICameratestValue>
              onSubmit={onSubmit}
              // validate={(values) => CameratestValue.validate(values)}
              validate={(values) => CameratestValue.validate(values)}
              initialValues={CameratestValue.getInitialValues({ ...status?.entry_data })}
            >
              {({ handleSubmit, submitting, submitError, values }) => (
                <form onSubmit={handleSubmit}>
                  <section className="section">
                    <Container>
                      <TitleField
                        title=" 第１回学生アナウンス大賞"
                        subtitle="カメラテスト 参加アンケート"
                      />
                      <hr/>
                      <ErrorNotification error={submitError}/>
                      <YesNoField name="participate" label={`カメラテストに参加しますか?`} required={true}/>
                      <TextInputField
                        name="name"
                        label="名前"
                        placeholder="姓　名"
                        width="medium"
                        required
                      />
                      <TextInputField
                        name="address"
                        label="住所"
                        placeholder="東京都港区お台場X-X-X"
                        width="medium"
                        required
                      />
                      <TextareaField
                        name="schedule"
                        label="3月22日・23日・24日の予定について特筆事項あればご記入ください"
                        placeholder="22日・23日・24日すべて特に予定はありません"
                      />
                      <SubmitButtonField
                        disabled={false}
                        label="回答する"
                      />
                    </Container>
                  </section>
                </form>
              )}
            </Form>
               */}

            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className={classNames("modal", {
          "is-active": showModal,
        })}
      >
        <div
          className="modal-background"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <div className="modal-content">
          <div className="notification is-success">
            回答を保存しました。変更する場合は再度回答ください。
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>
    </div>
  );

  // async function onSubmit(values: ICameratestValue) {
  //   const value: CameratestValue = CameratestValue.fromJSON(values);
  //   try {
  //     await axios.put(`https://app.q-uad.me/api/entry.json`, value.toRequest(), {
  //       headers: {
  //         "x-access-token": "42d55d08989969aa8960c4c224fc8b2c9348b6f3859efdd9efb30fcecb008ae1",
  //         "x-line-access-token": user?.["line-access-token"]
  //       }
  //     });
  //     setShowModal(true);
  //   } catch (error) {
  //     const formError = parseError(error);
  //     window.scrollTo(0, 0);
  //     return formError;
  //   }
  // }

  // function parseError(error: any) {
  //   return { [FORM_ERROR]: JSON.stringify(error) };
  // }
};
