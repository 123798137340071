import { Request } from "./Request";
import { Method } from "axios";
import { LineUser } from "../domains/LineUser";

export interface PostPhotoResponse {
  fileName: string;
  fullPath: string;
}

export class PostPhoto extends Request<PostPhotoResponse> {
  method = "post" as Method;
  constructor(application: string, user: LineUser | null | undefined) {
    super();
    this.headers = {
      "X-Access-Token": `${application}`,
      "X-Line-Access-Token": user?.["line-access-token"],
      "Content-Type": "multipart/form-data",
    };
    this.path = `/photo.json`;
  }

  file(fileList: any) {
    const [file] = fileList;
    const payload = new FormData();
    payload.append("image_file", file);
    this.data = payload;
    return this;
  }

  parse(response: any) {
    return response.data.result as PostPhotoResponse;
  }
}
