import { LineUser } from "../domains/LineUser";
import { useQuery } from "react-query";
// import { delay } from "../utils/delay";

export function useGetLineUser() {
  return useQuery<LineUser | undefined>(["liff"], async () => {
    // TODO: mock
    // if (process.env.NODE_ENV === "development") {
    //   // await delay(1000);
    //   return {
    //     display_name: "foobar",
    //     "line-user-id": "U11eedee1202e5051762b3814c0f4cae8",
    //     "line-access-token": "eyJhbGciOiJIUzI1NiJ9.ZfsB9GYO65lGmwA5OKH5UEczYGWHyVbF7E5h93G3dXGeeSG8PGmNvt7-1dRlFfHD9kiB6NHbjuRDRlOsm-5_9a0m0T1_8wBayjFZ5KVzWXXVMIkgj7pvu90m7vn0CP485o2n7HPL2mCvxLhTNz4FOtn_sEaTeuaU6wWv1EicyQg.8assWc1U5f9OuvYzs2oRtRS6oCGW9OsVlyDTh6HpXZM",
    //     friend_flag: true
    //   };
    // }
    return await liff
      .init({ liffId: process.env.REACT_APP_LIFF_ID! })
      .then(() => {
        // if (process.env.NODE_ENV !== "development" && !liff.isLoggedIn()) {
          if (!liff.isLoggedIn()) {
          liff.login({});
        }
      })
      .then(() => Promise.all([liff.getProfile(), liff.getFriendship()]))
      .then(([profile, friendship]) => {
        return {
          display_name: profile.displayName,
          "line-access-token": liff.getAccessToken(),
          "line-user-id": profile.userId,
          friend_flag: friendship.friendFlag,
        };
      })
      .catch(() => {
        return undefined;
      });
  });
}
