import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components/macro";
import classNames from "classnames";
import { usePostPhoto } from "../../hooks/usePostPhoto";
import { LineUser } from "../../domains/LineUser";
import { FieldLabel } from "./FieldLabel";

interface IPhotoUploaderProps {
  name: string;
  children: (props: IPhotoUploadFieldProps) => React.ReactNode;
  user: LineUser | undefined;
  path?: string;
  onMutate?: () => void;
  onSettled?: () => void;
  onBlur?: () => void;
}

export const PhotoUploader = ({
  name,
  user,
  children,
  path,
  onMutate = () => {},
  onSettled = () => {},
  onBlur,
}: IPhotoUploaderProps) => {
  const { upload, uploading, error: uploadError } = usePostPhoto(
    user,
    onMutate,
    onSettled
  );
  return (
    <Field name={name}>
      {({ input: { onChange }, meta }) => (
        <Field name={`${name}_file`}>
          {({ input: { value, onChange: onFileChange, ...input } }) => {
            const [file] = value;
            const handleChange = async ({
              target,
            }: React.ChangeEvent<HTMLInputElement>) => {
              if (target.files && target.files.length > 0) {
                await upload({
                  files: target.files,
                  onChange,
                  onFileChange,
                });
              }
            };
            return children({
              input,
              name: input.name,
              meta,
              file,
              uploading,
              error: uploadError,
              onChange: handleChange,
            });
          }}
        </Field>
      )}
    </Field>
  );
};

interface IPhotoUploadFieldProps {
  classname?: string;
  label?: React.ReactNode;
  required?: boolean;
  name: string;
  input: any;
  meta: any;
  onChange: any;
  error: Error | undefined;
  uploading: boolean;
  help?: React.ReactNode;
  file?: File|any;
}

export const PhotoUploadField = ({
  label,
  required,
  input,
  meta,
  file,
  onChange,
  uploading,
  error,
  help,
}: IPhotoUploadFieldProps) => {
  return (
    <div className={classNames("field")}>
      {label && <FieldLabel required={required}>{label}</FieldLabel>}

      <div className="control">
        <div
          className={classNames("file is-normal", {
            "has-name is-success": !!file,
          })}
        >
          <label className="file-label">
            <input
              {...input}
              className="file-input"
              type="file"
              accept="image/*"
              onChange={onChange}
            />
            <span className="file-cta">
              <FileIcon className="file-icon" loading={uploading}>
                <svg
                  className="svg-inline--fa fa-upload fa-w-16"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="upload"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                  />
                </svg>
              </FileIcon>
              <span className="file-label">
                {uploading ? `アップロード中...` : file ? `変更` : `選択`}
              </span>
            </span>
            {file && <span className="file-name">{file.name}</span>}
          </label>
        </div>
      </div>
      <p
        className={classNames("help", {
          "is-danger": error,
          "is-hidden": !error,
        })}
      >
        アップロードに失敗しました
      </p>
      <p
        className={classNames("help", {
          "is-danger": meta.touched && meta.error,
          "is-hidden": !(meta.touched && meta.error),
        })}
      >
        {meta.error}
      </p>
      {help && <p className={classNames("help")}>{help}</p>}
    </div>
  );
};

export const FileIcon = styled.span<{ loading?: boolean }>`
  ${({ loading }) =>
    !loading
      ? ``
      : `
    color: transparent !important;
    pointer-events: none;
    &::after {
    position: absolute;
      top: calc(50% - (1em / 2));
      position: absolute !important;
      animation: spinAround 500ms infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
    }
  `}
`;
