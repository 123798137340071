import { Field } from "react-final-form";
import classNames from "classnames";
import { FieldLabel } from "./FieldLabel";

interface IYesNoFieldProps {
  classname?: string;
  label?: React.ReactNode;
  required?: boolean;
  name: string;
}

export const YesNoField = ({
                             classname,
                             required,
                             name,
                             label
                           }: IYesNoFieldProps) => {

  return (
    <div className={classNames("field", classname)}>
      {label && <FieldLabel required={required}>{label}</FieldLabel>}
      <div className="control is-flex">
        <Field name={name} type="radio" value="yes">
          {({ input, meta: { touched, error } }) => (
            <>
              <input
                {...input}
                type="radio"
                id={`${input.name}_yes`}
                className={classNames("is-checkradio", {
                  "is-danger has-background-color": touched && error,
                  "is-link": !(touched && error)
                })} value="yes"
              />
              <label htmlFor={`${input.name}_yes`} style={{ paddingTop: 0 }}>
                はい
              </label>
            </>
          )}
        </Field>
        <Field name={name} type="radio" value="no">
          {({ input, meta: { touched, error } }) => (
            <>
              <input
                {...input}
                type="radio"
                id={`${input.name}_no`}
                className={classNames("is-checkradio", {
                  "is-danger has-background-color": touched && error,
                  "is-link": !(touched && error)
                })}
                value="no"
              />
              <label htmlFor={`${input.name}_no`} style={{ paddingTop: 0 }}>
                いいえ
              </label>
            </>
          )}
        </Field>
      </div>
      <Field name={name} type="radio">
        {({ input, meta: { touched, error } }) => (
          <p
            className={classNames("help", {
              "is-danger": touched && error,
              "is-hidden": !(touched && error)
            })}
          >
            {touched && error ? error : ""}
          </p>
        )}
      </Field>
    </div>
  );

  // return (
  //   <Field name={name} type="radio">
  //     {({ input, meta: { touched, error } }) => (
  //       <div className={classNames("field", classname)}>
  //         {label && <FieldLabel required={required}>{label}</FieldLabel>}
  //         <div className="control is-flex">
  //           <input
  //             {...input}
  //             type="checkbox"
  //             id={`${input.name}_yes`}
  //             className={classNames("is-checkradio",{
  //               "has-background-color is-danger": touched && error,
  //               "is-link" : !(touched && error)
  //             })}
  //           />
  //           <label htmlFor={`${input.name}_yes`} style={{ paddingTop: 0 }}>
  //             はい
  //           </label>
  //           <input
  //             {...input}
  //             type="checkbox"
  //             id={`${input.name}_no`}
  //             className={classNames("is-checkradio",{
  //               "has-background-color is-danger": touched && error,
  //               "is-link" : !(touched && error)
  //             })}
  //           />
  //           <label htmlFor={`${input.name}_no`} style={{ paddingTop: 0 }}>
  //             いいえ
  //           </label>
  //         </div>
  //         <p
  //           className={classNames("help", {
  //             "is-danger": touched && error,
  //             "is-hidden": !(touched && error),
  //           })}
  //         >
  //           {touched && error ? error : ""}
  //         </p>
  //       </div>
  //     )}
  //   </Field>
  // );
};
