import { Request } from "./Request";
import { Method } from "axios";
import { LineUser } from "../domains/LineUser";

export class PostEntry extends Request<void> {
  user: LineUser | null | undefined;
  method = "post" as Method;
  constructor(application: string, user: LineUser | null | undefined) {
    super();
    this.headers = {
      "X-Access-Token": `${application}`,
      "X-Line-Access-Token": user?.["line-access-token"],
    };
    this.path = `/entry/.json`;
    this.user = user;
  }

  entry(entry: any) {
    this.data = {
      ...entry,
    };
    return this;
  }

  parse(response: any) {
    return;
  }
}
