import { useMutation, useQueryClient } from "react-query";
import { LineUser } from "../domains/LineUser";
import { PostEntry } from "../networks/PostEntry";
import { GetStatusResponse } from "../networks/GetStatus";
import { Status } from "../domains/Status";
import FormValue from "../domains/FormValue";

export function usePostEntry(user: LineUser | undefined | null) {
  const queryKey = [
    "status",
    user?.["line-user-id"],
    user?.["line-access-token"],
  ];
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (value: FormValue) => {
      // TODO: mock
      // if (process.env.NODE_ENV === "development") {
      //   return delay(3000).then(() => {
      //     return;
      //   }) as Promise<void>;
      // }

      return new PostEntry(process.env.REACT_APP_APPLICATION_ID!, user)
        .entry(value.toRequest())
        .send();
    },
    {
      onSuccess: () => {
        const previousValue: Partial<GetStatusResponse> =
          queryClient.getQueryData(queryKey) ?? {};
        queryClient.setQueryData(queryKey, {
          ...previousValue,
          status: Status.APPLIED,
        });
        try {
          liff.sendMessages([
            {
              type: "text",
              text: `書類のご提出ありがとうございます。書類による一次審査の通過者発表は【2021年3月8日(月)】を予定しております。
発表日までこのままお待ちください。

なお、発表案内についてはこちらのLINEを通じて行いますので、必ず【学生アナウンス大賞エントリー用LINE】をブロックせずに友だち追加していただきますようお願いいたします。 

また、LINEアカウントが変更になった場合は、その旨を必ずこちらのLINEにてご連絡ください。             
              `,
            },
          ]);
        } catch {}
      },
    }
  );
  return {
    entry: mutateAsync,
  };
}
