import React from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../hooks/useGetLineUser";
import { useGetStatus } from "../../hooks/useGetStatus";
// Components
import { TitleField } from "../../components/forms/TitleField";
import { Redirect } from "../../components/utils/Redirect";
import { Status } from "../../domains/Status";

export default mount({
  "/": route({
    getView: () => <FormAppliedPage />,
  }),
});

const FormAppliedPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);

  //エントリーまだならルートへ
  if (status?.status !== Status.APPLIED) {
    return <Redirect url="/" />;
  }
  return (
    <div>
      <Helmet>
        <title>書類提出完了 | quadインターン2021</title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <TitleField
              title="quadインターン2021(demo)"
              subtitle="エントリーフォーム"
            />
            <hr />
            <div className="field">
              {`${user?.display_name} 様`}
              <br />
              書類提出ありがとうございました。
            </div>
            <div className="field">
              書類のご提出ありがとうございます。書類による一次審査の通過者発表は【2021年8月17日(火)】を予定しております。
              <br />
              発表日までこのままお待ちください。
              <br />
              <br />
              なお、発表案内についてはこちらのLINEを通じて行いますので、必ず【quadインターン2021(demo)】をブロックせずに友だち追加していただきますようお願いいたします。
              <br />
              <br />
              また、書類提出後にLINEアカウントが変更になった場合は、その旨を必ずこちらのLINEにてご連絡ください。
            </div>
            <hr />
            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
