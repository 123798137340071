import React from "react";
import classNames from "classnames";

interface ISubmitButtonFieldProps {
  submitting?: boolean;
  disabled: boolean;
  label?: string;
}

export const SubmitButtonField = ({
                                    submitting,
                                    disabled,
                                    label
                                  }: ISubmitButtonFieldProps) => {
  return (
    <div className="field">
      <div className="control">
        <button
          className={classNames(
            "button is-link is-fullwidth is-medium has-text-weight-bold",
            {
              "is-loading": submitting
            }
          )}
          type="submit"
          disabled={disabled}
        >
          {label ?
            <>{label}</> :
            <>応募する</>
          }
        </button>
      </div>
    </div>
  );
};
