import React from "react";
import { mount, compose, withView, lazy } from "navi";
import { View } from "react-navi";
// import FormClosedPage from "./closed";
import FormInputPage from "./input";
import FormAppliedPage from "./applied";
import ThanksPage from "./thanks";
import MeetingPage from "./meeting";
import AdditionalQuestionPage from "./offline";
import AnnouncePage from "./announce";

export default compose(
  withView(() => (
    <FormPage>
      <View />
    </FormPage>
  )),
  mount({
    "/": FormInputPage,
    "/entry": lazy(() => FormInputPage),
    "/applied": lazy(() => FormAppliedPage),
    "/result": lazy(() => ThanksPage),
    "/2nd": lazy(() => MeetingPage),
    "/3rd": lazy(() => AdditionalQuestionPage),
    "/4th": lazy(() => AnnouncePage)
  })
);

interface FormPageProps {
  children: React.ReactNode;
}

const FormPage = ({ children }: FormPageProps) => {
  return <div className="FormPage">{children}</div>;
};
