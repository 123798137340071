import React, { useMemo } from "react";
import { Field } from "react-final-form";
import classNames from "classnames";
import { FieldLabel } from "./FieldLabel";

interface ITextareaFieldProps {
  classname?: string;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  name: string;
  width?: "fullwidth" | "medium" | "large" | "small" | "none";
  help?: React.ReactNode;

  onBlur?: () => void
}

export const TextareaField = ({
  classname,
  required,
  name,
  placeholder,
  label,
  width = "none",
  help,
  onBlur
}: ITextareaFieldProps) => {
  const maxWidth = useMemo(() => {
    switch (width) {
      case "small":
        return 240;
      case "medium":
        return 320;
      case "large":
        return 480;
      default:
        return "none";
    }
  }, [width]);
  return (
    <Field name={name}>
      {({ input, meta: { touched, error } }) => (
        <div className={classNames("field", classname)}>
          {label && <FieldLabel required={required}>{label}</FieldLabel>}
          <div className="control">
            <textarea
              {...input}
              onBlur={ (e) => {
                input.onBlur(e)
                e.persist()
                onBlur?.()
              }}
              className={classNames("textarea is-medium", {
                "is-danger": touched && error,
              })}
              placeholder={placeholder}
              rows={10}
              style={{
                maxWidth,
              }}
            />
          </div>
          <p
            className={classNames("help", {
              "is-danger": touched && error,
              "is-hidden": !(touched && error),
            })}
          >
            {touched && error ? error : ""}
          </p>
          {help && <p className={classNames("help")}>{help}</p>}
        </div>
      )}
    </Field>
  );
};
